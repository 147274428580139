document.addEventListener('DOMContentLoaded', function () {
  // Маска полей ввода
  let phoneInputs = document.getElementsByClassName('input_phone');
  let phoneMaskOptions = {
    mask: '+{7} (000) 000-00-00'
  };

  Array.from(phoneInputs).forEach((input) => {
    // console.log(el.tagName);
    IMask(input, phoneMaskOptions);
  });
});

// AJAX отправка сообщения
$(document).ready(function () {
  $('form.js-ajax-form').submit(function (event) {
    event.preventDefault();

    let formID = $(this).attr('id');
    let formNm = $('#' + formID);

    formNm.addClass('inloading');

    // Сериализуем поля
    let inputs = document.querySelectorAll('#' + formID + ' [data-label]')
    let serializeObj = {}
    let serializeArr = []
    let i = 0
    for (let input of inputs) {
      let label = input.dataset.label
      let value = input.value

      item = {
        'label': label,
        'value': value
      }

      // serializeArr.push(item)
      // serializeObj.push(item)
      serializeObj[i] = item;
      i = i + 1

      // if (obj[key] !== undefined) {
      //   if (!Array.isArray(obj[key])) {
      //     obj[key] = [obj[key]];
      //   }
      //   obj[key].push(value);
      // } else {
      //   obj[key] = value;
      // }
    }

    console.log('serializeObj', serializeObj)

    // Convert Form To JSON
    function convertFormToJSON(form) {
      const array = $(form)
        .serializeArray(); // Encodes the set of form elements as an array of names and values.
      const json = {};
      $.each(array, function () {
        json[this.name] = this.value || "";
      });
      return json;
    }

    // Формируем сообщение
    let mail = '';
    mail += '<h2>Заявка с сайта</h2><br><br>';

    for (const [index, item] of Object.entries(serializeObj)) {
      let label = item.label
      let value = item.value
      console.log(`${label}: ${value}`);
      mail += '<b>' + label + ":</b> " + value + '<br>';
    }

    $.ajax({
      type: "POST",
      url: '/mailer/sender.php',
      data: {
        mail: mail
      },
      success: function (data) {
        // Вывод текста результата отправки
        formNm.removeClass('inloading');
        $(formNm).html(data);
      },
      error: function (jqXHR, text, error) {
        formNm.removeClass('inloading');
        // Вывод текста ошибки отправки
        $(formNm).html(error);
      }
    });

    return false;
  });
});

// Табы
document.addEventListener('DOMContentLoaded', function () {
  var connectTabs = new Tabs();
});

// Мобильная липкая шапка
$(window).scroll(function () {
  let stickyHeader = $('.js-mobile-sticky-header');

  if ($(this).scrollTop() > 40) {
    stickyHeader.addClass("js-mobile-sticky-header--sticky");
  } else {
    stickyHeader.removeClass("js-mobile-sticky-header--sticky");
  }
});

// Десктопная липкая шапка
$(window).scroll(function () {
  let stickyHeaderDesktop = $('.js-desktop-sticky-header');

  if ($(this).scrollTop() > 200) {
    stickyHeaderDesktop.addClass("js-desktop-sticky-header--sticky");
  } else {
    stickyHeaderDesktop.removeClass("js-desktop-sticky-header--sticky");
  }
});